// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');


//:host {
//  font-family: 'AppFont', sans-serif;
//}

@font-face {
    font-family: AppFont;
    src: url("/assets/fonts/rubic/static/Rubik-Regular.ttf");
}

@font-face {
    font-family: AppFont500;
    src: url("/assets/fonts/rubic/static/Rubik-Medium.ttf");
}

@font-face {
    font-family: AppFont700;
    src: url("/assets/fonts/rubic/static/Rubik-Bold.ttf");
}

@font-face {
    font-family: CourgetteRegular;
    src: url("/assets/fonts/courgette/Courgette-Regular.ttf");
}

@font-face {
    font-family: IBMPlexSans;
    src: url("/assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf");
}


:root,
:root[mode=ios],
:root[mode=md] {
    --ion-font-family: 'AppFont';
}


/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --main-blue: #80B0B1;
    --main-light-blue: #CFF0F1;
    --main-green: #83B25F;
    --main-dark-green: #759f55;
    --main-light-green: #D7F3C2;
    --main-white: #ffffff;
    --main-grey: #F2F2F2;
    --main-light-grey: #CFCFCF;
    --main-dark: #3F403F;
    --main-light-yellow: #FFF5BD;
    --main-yellow: #EBC700;
    --main-light-red: #EA9D9D;
    --main-red: #EB5757;

    --main-light-pink: #FECECD;
    --main-pink: #F4A28C;


    --ion-text-color: var(--main-dark);

    --highlight-color: var(--main-light-grey);


    --height-footer-sm: 118px;
    --height-student-footer-sm: 85px;
    --height-footer-lg: 80px;
    --height-header-lg: 60px;
    --margin-top-footer: 20px;
    --margin-student-top-footer: 0;

    --ion-color-step-650: #3F403F;
}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #428cff;
//    --ion-color-primary-rgb: 66, 140, 255;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255, 255, 255;
//    --ion-color-primary-shade: #3a7be0;
//    --ion-color-primary-tint: #5598ff;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80, 200, 255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255, 255, 255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106, 100, 255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47, 223, 117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0, 0, 0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255, 213, 52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0, 0, 0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255, 73, 97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255, 255, 255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244, 245, 248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0, 0, 0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #3F403F;
//    --ion-color-medium-rgb: 152, 154, 162;
//    --ion-color-medium-contrast: #FFFFFF;
//    --ion-color-medium-contrast-rgb: 0, 0, 0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34, 36, 40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255, 255, 255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #ffffff;
//    --ion-background-color-rgb: 0, 0, 0;
//
//    --ion-text-color: #000000;
//    --ion-text-color-rgb: 255, 255, 255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    //--ion-item-background: transparent;
//
//    //--ion-card-background: transparent;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-950);
//    --ion-toolbar-background: var(--ion-color-step-850);
//    --ion-toolbar-border-color: var(--ion-color-step-750);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #ffffff;
//    --ion-background-color-rgb: 18, 18, 18;
//
//    --ion-text-color: #222222;
//    --ion-text-color-rgb: 255, 255, 255;
//
//    --ion-border-color:  var(--ion-color-step-750);
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    //--ion-item-background: transparent;
//
//    --ion-toolbar-background: #ffffff;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}

html {
    /*
     * For more information on dynamic font scaling, visit the documentation:
     * https://ionicframework.com/docs/layout/dynamic-font-scaling
     */
    --ion-dynamic-font: var(--ion-default-dynamic-font);
}


.menu-popover {
    --width: 100%;
    //--height: calc(100vh - 175px);
    --offset-y: 19px;
    --max-height: 100%;
    border-radius: 0;
    padding-top: env(safe-area-inset-top);
}

.menu-popover [part="content"] {
    border-radius: 0;
}

.student-menu-popover {
    //--width: 100%;
    --width: 102%;
    --offset-y: 11px;
    //--max-height: 100%;
    border-radius: 0;
    overflow: auto;
}

.student-menu-popover [part="content"] {
    border-radius: 0;
}

.select-popover {
    //--offset-y:-37%;
    --max-height: 400px;
}


.language-popover {
    --width: auto;
}


.content-btn {
    width: 100%;
    height: 64px;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;

    .content-inner {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        //gap: 10px;
        width: 90%;

        .svg-wrapper {
            width: 40px !important;
            margin-right: 10px;
        }

    }

    .arrow {
        text-align: right;
        width: 10%;

    }

    .input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.content-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    --padding-start: 0;
    --inner-padding-end: 0;
    --border-color: var(--main-grey);

    background-color: var(--main-white);
    --background: var(--main-white);
    color: var(--main-dark);

    &:last-child {
        --inner-border-width: 0 0 0.55px 0 !important;
        --border-width: 0px !important;
    }

    &:hover {
        color: var(--main-green);
    }

}

ion-content {
    background-color: var(--main-grey);
    --background: var(--main-grey);
    padding-bottom: env(safe-area-inset-bottom) !important;

}

ion-header {
    background-color: var(--main-white);
    --background: var(--main-white);
}

.btn {
    padding: 10px 18px 10px 18px;
    border-radius: 4px;
    color: var(--main-dark);

    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    text-align: left;

    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-primary {
    padding: 10px 18px 10px 18px;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid var(--main-dark);
    background: transparent;
    color: var(--main-dark);

    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    text-align: left;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: var(--main-dark);
        color: var(--main-white);

        svg {
            fill: var(--main-white);

            path:not(.not-fill) {
                fill: var(--main-white);
            }

        }
    }
}


.grey-btn {
    background-color: var(--main-grey);
    --background: var(--main-grey);
    color: var(--main-dark);

    &:hover {
        background-color: var(--main-green);
        --background: var(--main-green);
        color: var(--main-white);

        svg {
            fill: var(--main-green);

            path:not(.not-fill) {
                fill: var(--main-green);
            }

        }

        .arrow {
            svg {
                fill: var(--main-white);

                path:not(.not-fill) {
                    fill: var(--main-white);
                }
            }
        }


    }
}

.green-btn {
    background-color: var(--main-green);
    --background: var(--main-green);
    color: var(--main-white);
    border-radius: 4px;

    &:hover {
        background-color: var(--main-dark-green);
        color: var(--main-white);
    }

    .content-inner {
        svg {
            fill: var(--main-green);

            path:not(.not-fill) {
                fill: var(--main-green);
            }

        }
    }

    svg {
        fill: var(--main-white);

        path:not(.not-fill) {
            fill: var(--main-white);
        }
    }

}

.dark-btn {
    background-color: var(--main-dark);
    color: var(--main-white);

    &:hover {
        background-color: #333433;
        color: var(--main-white);
    }

    svg {
        fill: var(--main-white);

        path:not(.not-fill) {
            fill: var(--main-white);
        }
    }

}

.white-btn {
    background-color: var(--main-white);
    --background: var(--main-white);
    color: var(--main-dark);
    border: 1px solid var(--main-light-grey);

    &:hover {
        background-color: var(--main-light-grey);
        --background: var(--main-light-grey);
    }
}

.light-yellow-background {
    background-color: var(--main-light-yellow);
    --background: var(--main-light-yellow);
    color: var(--main-dark);
}

.light-yellow-background:hover {
    .wrapper-btn {
        background-color: #f6ebae;
    }

    background-color: #f6ebae;
}

.yellow-background {
    background-color: var(--main-yellow);
    --background: var(--main-yellow);
    color: var(--main-dark);
}

.light-green-background {
    background-color: var(--main-light-green);
    --background: var(--main-light-green);
    color: var(--main-dark);
}

.light-green-background:hover {
    background-color: #cdf1b1;
    --background: #cdf1b1;
    color: var(--main-dark);
}


ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.edit-child-photo-modal {
    --width: 544px;
    //--height: 425px;
    --height: auto;
    --border-radius: 8px;
    //background-color: var(--main-white);
}



.edit-child-photo-modal,.inner-scroll {
    background: transparent !important;
}

.popover-class {
    --offset-y: -71px;
}

.popover-class [part="content"] {
    min-width: 160px;
}

.popover-class.test {
    --offset-y: -98px !important;
}

.popover-class.share-popover {
    --offset-y: -12% !important;
}

.popover-class.share-popover.test-link {
    --offset-y: -8% !important;
}


#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}


#container p {
    font-size: 16px;
    line-height: 22px;

    color: #8c8c8c;

    margin: 0;
}

#container a {
    text-decoration: none;
}

.grid-md {
    //--ion-grid-width-xs: 95%;
    //--ion-grid-width-sm: 384px;
    //--ion-grid-width-md: 664px;
    --ion-grid-width-lg: 720px;
    --ion-grid-width-xl: 794px;

    min-height: calc(100vh - var(--height-footer-lg) - var(--height-header-lg) - var(--margin-top-footer));
    height: auto;
    padding-top: 12px;

    @media (max-width: 768px) {
        min-height: calc(100vh - var(--height-footer-sm) - var(--height-header-lg) - var(--margin-top-footer));
        padding-inline-start: 14px;
        padding-inline-end: 14px;
    }
}

.page-grid {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 12px;
    min-height: calc(100vh - var(--height-footer-lg) - var(--height-header-lg) - var(--margin-top-footer) - env(safe-area-inset-bottom));
    height: auto;

    @media (max-width: 768px) {
        min-height: calc(100vh - var(--height-footer-sm) - var(--height-header-lg) - var(--margin-top-footer) - env(safe-area-inset-bottom));

        padding-inline-start: 14px;
        padding-inline-end: 14px;
    }
}

.student-grid {
    --ion-grid-width-xs: 100%;
    --ion-grid-width-sm: 100%;
    --ion-grid-width-md: 100%;
    --ion-grid-width-lg: 90%;
    --ion-grid-width-xl: 1120px;
}

.student-grid.student-page {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 12px;
    min-height: calc(100vh - var(--height-footer-lg) - var(--height-header-lg) - var(--margin-top-footer) - env(safe-area-inset-bottom));
    height: auto;

    @media (max-width: 768px) {
        min-height: calc(100vh - var(--height-student-footer-sm) - var(--height-header-lg) - var(--margin-top-footer) - env(safe-area-inset-bottom));
        padding-inline-start: 0;
        padding-inline-end: 0;
    }

}


.program-details-modal {
    --height: 900px;
    --max-height: 88%;
    background: transparent !important;
    @media screen and (max-width: 767px) {
        --height: 90%;
        --width: 95%;
    }
}

.program-details-modal [part="content"] {
    border-radius: 8px
}

ion-modal.chose-modal [part="content"] {
    border-radius: 16px;

}


.program-details-modal.cashback {
    --height: 40%;

    @media screen and (max-width: 767px) {
        --height: 32%;
    }
}

.ion-content {
    --background: var(--main-white);
}

ion-modal {
    padding-top: env(safe-area-inset-top);

    &.ios {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

ion-modal {

    ion-content {
        background-color: var(--main-white);
        --background: var(--main-white);
    }
}

ion-modal [part="content"] {
    border-radius: 8px;
}

ion-modal.auto-height.birthday-card {
    --width: auto;

    .ion-page {
        .inner-content {
            max-height: 95vh;
            overflow: auto;
            padding: 0;
        }
    }
}

ion-modal.auto-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;
    //--width: auto;
    --border-radius: 8px;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 95vh;
            overflow: auto;
            padding: 10px;
        }
    }
}


ion-modal.auto-height.full-height {
    &.bottom {
        align-items: flex-end;
    }

    --height: auto;
    --border-radius: 8px;

    .ion-page {
        position: relative;
        display: block;
        contain: content;

        .inner-content {
            max-height: 85vh;
            height: 85vh;
            overflow: auto;
            padding: 10px;
        }
    }
}


.price-popover {
    --padding-top: 12px !important;
    --padding-bottom: 12px !important;
    --padding-start: 12px !important;
    --padding-end: 12px !important;

    .info {
        font-family: Rubik, sans-serif;
        margin-bottom: 6px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.59px;
        text-align: left;
        color: var(--main-dark);
    }

    .wrapper {
        padding: 12px 14px;
        border-radius: 4px;

        width: 192px;

    }

    a {
        color: #5D5C5C;
    }

    .agreement {
        font-size: 10px;
        font-weight: 400;
        line-height: 11.85px;
        text-align: left;
        color: #5D5C5C;
    }

    .info:last-child {
        margin-bottom: 0;
    }
}

.study-progress {
    --width: 350px;

    font-family: 'AppFont', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    text-align: left;

    .title {
        margin-bottom: 8px;
        font-size: 14px;
        font-family: 'AppFont500', sans-serif;
        line-height: 16.59px;
        text-align: left;
    }
}

ion-alert {
    color: var(--main-grey);
    font-family: 'AppFont', sans-serif;
    font-size: 14px;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #CFCFCF;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #F2F2F2;
    }

    ::-webkit-scrollbar-track {
        background-color: #F2F2F2
    }

    [aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
        border-color: var(--main-green);
    }

    .alert-radio-inner.sc-ion-alert-md {
        background-color: var(--main-green);
    }

    .alert-button.sc-ion-alert-md {
        color: var(--main-dark);
    }

}

.single-select-alert {
    .alert-head {
        display: none;
    }

}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: #CFCFCF;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #F2F2F2;
}

::-webkit-scrollbar-track {
    background-color: #F2F2F2
}

ion-loading.app-loading {
    --spinner-color: var(--main-green);

    .loading-wrapper {
        font-family: 'AppFont', sans-serif;

        padding-top: 50px;
        padding-bottom: 12px;
        padding-inline-end: 12px;
        padding-inline-start: 12px;
        //padding-inline-start: 50px;
        background-image: url(/assets/images/icons/login-logo.svg);
        background-position: center 10px; /* Position the image on the left side, vertically centered */
        background-repeat: no-repeat; /* Prevent the image from repeating */
        background-size: 50px; /* Set the width of the image to 50px, height will auto adjust to maintain aspect ratio */

        .loading-content {
            margin-inline-start: 4px !important;
        }

    }


    //color: var(--main-green);
}

.d-none {
    display: none;
}


ion-accordion-group {
    ion-accordion.is-schedule {
        .ion-accordion-toggle-icon {
            display: none;
        }
    }
}

ion-select-popover {
    font-family: "AppFont", sans-serif;
}

.not-allowed {
    cursor: not-allowed !important;
}

.select-transfer-lesson-item {
    font-size: 18px !important;
    white-space: normal !important;
    transform: none !important;

    @media screen and (max-width: 767px) {
        font-size: 15px !important;
    }

    ion-radio::part(label) {
        white-space: normal !important;
        transform: none !important;

    }

    .label-text-wrapper {
        white-space: normal !important;
        transform: none !important;
    }
}
//ion-content{
//    --keyboard-offset: 0 !important;
//}
